<template>
  <div
    v-if="updateExists"
    class="tw-fixed tw-inset-0 tw-z-30 tw-flex tw-items-center tw-w-full tw-bg-white"
  >
    <div class="tw-max-w-lg tw-m-auto tw-text-center">
      <div class="tw-mb-4 tw-text-4xl tw-font-semibold tw-leading-tight">
        Webamp has been updated
      </div>
      <button
        class="tw-px-4 tw-py-2 tw-font-bold tw-text-white tw-bg-purple-500 tw-rounded hover:tw-bg-purple-600 focus:tw-outline-none focus:tw-shadow-outline"
        @click="refreshApp"
      >
        <i class="fas fa-redo-alt" /> Click to update!
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
    };
  },

  created() {
    // Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true });
    // Refresh all open app tabs when a new service worker is installed.
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });
  },

  methods: {
    showRefreshUI(e) {
      // Display a button inviting the user to refresh/reload the app due
      // to an app update being available.
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      this.registration = e.detail;
      this.updateExists = true;
    },

    refreshApp() {
      // Handle a user tap on the update app button.
      this.updateExists = false;
      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage('skipWaiting');
    },
  },
};
</script>
